import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import { Analytics } from "@vercel/analytics/react";

import Router from "./router";
import i18n from "./translation";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient()


const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </I18nextProvider>
    <Analytics />
  </BrowserRouter>
);

ReactDOM.render(<App />, document.getElementById("root"));
